<ng-container *ngIf="!passwordFilled; else passwordFilledBlock">
  <div [ngSwitch]="step" [formGroup]="signupFormGroup">
    <div *ngSwitchCase="0" [formGroupName]="'personalInfo'">
      <div class="box-title">
        <div class="icon-box info"><i class="icon icon-info-circle"></i></div>
        <span class="title">Mes infos</span>
        <span>Nous aimerions en connaitre un peu plus sur vous, afin de fiabiliser votre compte</span>
      </div>

      <div>
        <label for="firstName">Prénom</label>
        <ed-textfield innerId="firstName" class="textfield" [formControlName]="'firstName'" [placeholder]="'Jeanne'"></ed-textfield>
      </div>

      <div>
        <label for="lastName">Nom</label>
        <ed-textfield innerId="lastName" class="textfield" [formControlName]="'lastName'" [placeholder]="'Martin'"></ed-textfield>
      </div>

      <div>
        <label for="mobilePhone">Numéro de téléphone</label>
        <ed-textfield innerId="mobilePhone"
                      class="textfield"
                      [formControlName]="'mobilePhone'"
                      [placeholder]="'06 01 02 03 04'"
                      mask="00 00 00 00 00||+00 0 00 00 00 00"></ed-textfield>
      </div>

      <div class="button-group">
        <ed-button [type]="ButtonTypes.Submit"
                   (click)="nextStep()">Continuer</ed-button>
      </div>

      <div class="bottom-text">
        Vous avez déjà un compte ? <a routerLink="/login">Se connecter</a>
      </div>
    </div>

    <div *ngSwitchCase="1" [formGroupName]="'securityInfo'">
      <div class="box-title">
        <div class="icon-box info"><i class="icon icon-key-01"></i></div>
        <span class="title">Choisir un mot de passe</span>
      </div>

      <div>
        <label for="email">Adresse email</label>
        <ed-textfield class="textfield" innerId="email" [formControlName]="'email'"></ed-textfield>
      </div>

      <div>
        <label for="password">Mot de passe</label>
        <ed-textfield innerId="password"
                      [formControlName]="'password'"
                      [type]="passwordType"
                      (onIconClick)="toggleVisibility()"
                      [icon]="getVisibilityToggleButtonIcon()"
                      [iconPosition]="'right'"
                      [iconClickable]="true"
                      [validMessage]="'Le format du mot de passe est valide'"
                      [placeholder]="'Choisissez votre mot de passe'"></ed-textfield>
      </div>

      <div class="cgu-box">
        <ed-checkbox [formControlName]="'cgu'"></ed-checkbox>
        <div class="cgu-text">J'ai pris connaissance et accepte les <a [href]="getDownloadPdfCgu()" target="_blank">conditions générales d'utilisation</a> du service</div>
      </div>

      <div class="rgpd-box">
        <ed-checkbox [formControlName]="'rgpd'"></ed-checkbox>
        <div class="rgpd-text">Je m'engage à respecter le RGPD et accepte que le propriétaire du logement reste
          <span class="emphasis-strong">responsable et souverain des données</span> associées au carnet
        </div>
      </div>

      <div class="button-group">
        <ed-button [type]="ButtonTypes.Submit"
                   [disabled]="signupFormGroup.invalid"
                   (click)="onSubmit()">Créer mon compte</ed-button>
      </div>

      <div class="back">
        <span (click)="previousStep()"><i class="icon icon-arrow-left"></i> Retour</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #passwordFilledBlock>
  <div class="icon-box valid"><i class="icon icon-check-circle"></i></div>
  <h1>Création réussie !</h1>

  <div class="succeeded">
    <div>Votre compte a bien été créé.</div>
    <div>Vous pouvez dès à présent vous connecter avec votre email et le mot de passe que vous venez de renseigner.</div>
    <a routerLink="/login">
      <ed-button>Se connecter</ed-button>
    </a>
  </div>
</ng-template>
