import { Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertService, ButtonTypes } from '@ed---interne/ng-uui-components';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { emailValidator } from '../../shared/validators/email.validator';
import { Login } from '../../state/auth.state';

@Component({
  selector: 'cil-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  public ButtonTypes = ButtonTypes;
  public loading = false;
  public displayError = false;

  public loginFormGroup = this.formBuilder.group({
    email: ['', [emailValidator()]],
    password: [''],
  });

  private _subscriptions: Subscription[] = [];

  constructor(
    private readonly alertService: AlertService,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
  ) {}

  public ngOnDestroy(): void {
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
  }

  public onSubmit(): void {
    this.loading = true;

    const sub = this.store
      .dispatch(
        new Login({
          email: this.loginFormGroup.value.email || '',
          password: this.loginFormGroup.value.password || '',
        }),
      )
      .subscribe({
        error: (err) => {
          this.alertService.error('Connexion', err.error.content?.reason || 'Le serveur ne répond pas');

          this.loading = false;
        },
      });
    this._subscriptions.push(sub);
  }
}
