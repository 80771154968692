<ng-container *ngIf="!emailSentTo; else emailSentBlock">
  <div class="icon-box info"><i class="icon icon-key-01"></i></div>
  <h1>Mot de passe oublié ?</h1>
  <div class="subtitle">Renseignez votre email et nous vous enverrons des instructions pour le réinitialiser</div>

  <form (ngSubmit)="onSubmit()"
        [formGroup]="forgotPasswordFormGroup">
    <div class="form-group">
      <ed-textfield innerId="email"
                    [type]="'email'"
                    [placeholder]="'jeanne@martin.fr'"
                    [formControlName]="'email'"></ed-textfield>
    </div>

    <div class="button-group">
      <ed-button [type]="ButtonTypes.Submit"
                 [disabled]="forgotPasswordFormGroup.invalid">Réinitialiser mon mot de passe</ed-button>
    </div>
  </form>
</ng-container>

<ng-template #emailSentBlock>
  <div class="icon-box info"><i class="icon icon-mail-01"></i></div>
  <h1>Consultez vos emails</h1>
  <div class="subtitle">Les instructions pour réinitialiser votre mot de passe ont été transmis à <br><b>{{ emailSentTo }}</b></div>
  <div class="email-info">Email non reçu ? <a (click)="onSubmit()">Cliquez pour renvoyer</a></div>
</ng-template>

<div class="back">
  <a routerLink="/"><i class="icon icon-arrow-left"></i>Se connecter</a>
</div>
