import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule, ButtonModule, CheckboxModule, TextfieldModule } from '@ed---interne/ng-uui-components';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { PublicRoutingModule } from './public-rooting.module';
import { PublicComponent } from './public.component';
import { PublicService } from './public.service';
import { SignupComponent } from './signup/signup.component';

@NgModule({
  imports: [
    CommonModule,
    PublicRoutingModule,
    ReactiveFormsModule,
    TextfieldModule,
    CheckboxModule,
    AlertModule,
    ButtonModule,
  ],
  declarations: [PublicComponent, ChangePasswordComponent, ForgotPasswordComponent, LoginComponent, SignupComponent],
  providers: [PublicService],
  bootstrap: [PublicComponent],
})
export class PublicModule {}
